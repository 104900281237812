import Description from "../Description";

const AboutUs = () => {

  return (
    <Description />
  );
};

export default AboutUs;
